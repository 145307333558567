<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-tabs
      v-model="tab"
      color="purple"
    >
      <v-tab
        key="mitra"
        class="text-capitalize"
      >
        Mitra
      </v-tab>

      <v-tab
        key="perusahaan"
        class="text-capitalize"
      >
        Verifikasi Bank
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- TAB MITRA -->
      <v-tab-item key="mitra">
        <template v-if="partnerCompanyView">
          <div
            id="personal-tab-container"
            style="padding: 12px;"
          >
            <v-data-table
              :headers="headers"
              :items="mitra"
              :items-per-page="25"
              class="elevation-0"
              mobile-breakpoint="100"
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="mt-4 px-8 pt-4 pb-10"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Cari"
                      color="purple"
                      single-line
                      hide-details
                      clearable
                      outlined
                      dense
                      @keyup.enter.native="filterData"
                      @click:append="filterData"
                      @click:clear="filterClear"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.contract_validity_date="{ item }">
                <span v-if="item.contract_validity_date !== null">
                  {{ item.contract_validity_date }}
                </span>
                <span v-else>
                  -
                </span>
              </template>

              <template v-slot:item.share_percent="{ item }">
                <span v-if="item.share_percent !== null">
                  {{ item.share_percent + '%' }}
                </span>
                <span v-else>
                  0%
                </span>
              </template>

              <template v-slot:item.wallet_saldo="{ item }">
                <div
                  style="
                  display: flex;
                  justify-content: space-between;
                "
                >
                  <span>
                    Rp
                  </span>

                  <span>
                    {{ item.wallet_saldo | dotThousandSeparator }}
                  </span>
                </div>
              </template>

              <template v-slot:item.verification_dat="{ item }">
                <template v-if="item.verification_dat.btn_enable">
                  <v-btn
                    class="elevation-0"
                    width="110px"
                    height="32px"
                    style="
                    background: #7A1878;
                  "
                    @click="showDialogVerifikasiPersonal(item)"
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="!item.verification_dat.btn_enable && !item.verified">
                  <v-btn
                    class="elevation-0"
                    width="110px"
                    height="32px"
                    disabled
                    style="
                    background: #E6E6E6;
                  "
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>

                <template v-if="item.verified">
                  <v-btn
                    class="elevation-0"
                    width="110px"
                    height="32px"
                    style="
                    background: #FFFFFF;
                    border: 1px solid #5DB025;
                    outline: none;
                    cursor: default;
                  "
                  >
                    <v-icon color="green">
                      mdi-check
                    </v-icon>
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #5DB025;
                    "
                    >
                      Terverifikasi
                    </span>
                  </v-btn>
                </template>
              </template>

              <template v-slot:item.actions="{ item }">
                <div
                  class="action-container"
                  style="
                  width: 36px;
                  height: 29px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  cursor: pointer;
                "
                  @click="toPersonalDetail(item)"
                >
                  <v-icon
                    class="action-icon"
                    style="font-size: 16px"
                  >
                    fas fa-eye
                  </v-icon>
                </div>
              </template>

              <template v-slot:footer>
                <div class="text-center my-5 py-3">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    color="purple"
                    :total-visible="5"
                    @input="onChangePage"
                  />
                  <!-- <span style="font-size: 12px; font-style: italic;">
                  Page {{ page }} of {{ pageCount || 1 }}
                </span> -->
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                  </span>
                </div>
              <!-- <v-row class="mt-n16">
                <v-col class="text-right pr-8">
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
                  </span>
                </v-col>
              </v-row> -->
              </template>

              <template
                v-if="isAllowDetail"
                v-slot:[`item.actions`]="{ item }"
              >
                <v-btn
                  small
                  dark
                  color="purple"
                  class="mr-2"
                  @click="detailButtonClick(item)"
                >
                  DETAIL
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </template>

        <template v-else>
          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              Tidak ada akses
            </v-col>
          </v-row>
        </template>
      </v-tab-item>

      <!-- TAB PERUSAHAAN / BANK -->
      <v-tab-item key="perusahaan">
        <template v-if="partnerBankVerificationView">
          <div
            id="personal-tab-container"
            style="padding: 12px;"
          >
            <v-data-table
              :headers="headerPerusahaan"
              :items="perusahaan"
              :items-per-page="25"
              class="elevation-0"
              mobile-breakpoint="100"
              hide-default-footer
            >
              <template v-slot:top>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    class="mt-4 px-8 pt-4 pb-10"
                  >
                    <v-text-field
                      v-model="searchPerusahaan"
                      append-icon="mdi-magnify"
                      label="Cari Perusahaan"
                      color="purple"
                      single-line
                      hide-details
                      clearable
                      outlined
                      dense
                      @keyup.enter.native="filterDataPerusahaan"
                      @click:append="filterDataPerusahaan"
                      @click:clear="filterClearPerusahaan"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.trx_total="{ item }">
                <div
                  style="
                  display: flex;
                  justify-content: space-between;
                "
                >
                  <span>
                    Rp
                  </span>

                  <span>
                    {{ (item.trx_total || 0) | dotThousandSeparator }}
                  </span>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <template>
                  <v-btn
                    class="elevation-0"
                    width="110px"
                    height="32px"
                    style="
                    background: #7A1878;
                  "
                    @click="showDialogVerifikasiPerusahaan(item)"
                  >
                    <span
                      class="text-capitalize"
                      style="
                      font-weight: bold;
                      font-size: 12px;
                      color: #FFFFFF;
                    "
                    >
                      Verifikasi
                    </span>
                  </v-btn>
                </template>
              </template>

              <template v-slot:footer>
                <div class="text-center my-5 py-3">
                  <v-pagination
                    v-model="pagePerusahaan"
                    :length="pageCountPerusahaan"
                    color="purple"
                    :total-visible="5"
                    @input="onChangePagePerusahaan"
                  />
                  <!-- <span style="font-size: 12px; font-style: italic;">
                  Page {{ pagePerusahaan }} of {{ pageCountPerusahaan || 1 }}
                </span> -->
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCountPerusahaan + ' dari ' + itemTotalPerusahaan + ' ditampilkan' }}
                  </span>
                </div>
              <!-- <v-row class="mt-n16">
                <v-col class="text-right pr-8">
                  <span style="font-size: 12px; font-style: italic;">
                    {{ itemCountPerusahaan + ' dari ' + itemTotalPerusahaan + ' ditampilkan' }}
                  </span>
                </v-col>
              </v-row> -->
              </template>
            </v-data-table>
          </div>
        </template>

        <template v-else>
          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              Tidak ada akses
            </v-col>
          </v-row>
        </template>
      </v-tab-item>
    </v-tabs-items>

    <!-- DIALOG VERIFIKASI PERSONAL -->
    <v-dialog
      v-model="dialogVerifikasiPersonal"
      max-width="600"
    >
      <template v-if="partnerVerification">
        <v-card class="px-4 py-4">
          <v-row>
            <v-col
              cols="11"
              sm="11"
              class="text-center pl-15"
            >
              <span style="font-weight: bold">
                Verifikasi Mitra
              </span>
            </v-col>

            <v-col
              cols="1"
              sm="1"
              class="text-right"
            >
              <span
                style="cursor: pointer"
                @click="dialogVerifikasiPersonal = false"
              >
                X
              </span>
            </v-col>
          </v-row>
          {{ partnerVerification }}
          <v-tabs
            v-model="tabDialog"
            class="mt-3"
            color="purple"
            fixed-tabs
          >
            <v-tab
              key="infoMitra"
              class="text-capitalize"
            >
              Informasi Personal
            </v-tab>

            <v-tab
              key="infoPerusahaan"
              class="text-capitalize"
            >
              Informasi Perusahaan
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabDialog">
            <v-tab-item key="infoMitra">
              <v-row class="mt-5">
                <v-col
                  class="font-common"
                  cols="4"
                  sm="4"
                >
                  Nama
                </v-col>

                <v-col
                  class="font-common-bold"
                  cols="8"
                  sm="8"
                >
                  <span v-if="selectedMitra !== null">
                    {{ selectedMitra.verification_dat.personal_dat.name || '-' }}
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="font-common"
                  cols="4"
                  sm="4"
                >
                  Email
                </v-col>

                <v-col
                  class="font-common-bold"
                  cols="8"
                  sm="8"
                >
                  <span v-if="selectedMitra !== null">
                    {{ selectedMitra.verification_dat.personal_dat.email || '-' }}
                  </span>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="font-common"
                  cols="4"
                  sm="4"
                >
                  Dokumen
                </v-col>

                <v-col
                  class="font-common-bold"
                  cols="8"
                  sm="8"
                  style="
                  display: flex;
                  flex-direction: column;
                "
                >
                  <span class="font-common-bold">
                    KTP
                  </span>

                  <template v-if="selectedMitra !== null">
                    <span v-if="selectedMitra.verification_dat.personal_dat.ktp !== null">
                      <v-img
                        :src="selectedMitra.verification_dat.personal_dat.ktp.data.file_path"
                        contain
                        width="300px"
                        height="300px"
                        style="cursor: pointer"
                        @click="downloadAttachment(selectedMitra.verification_dat.personal_dat.ktp.data.file_path)"
                      />
                    </span>
                    <span v-else>
                      image
                    </span>
                  </template>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  class="pt-5 font-common"
                  cols="4"
                  sm="4"
                >
                  Nomor NIK
                </v-col>

                <v-col
                  cols="8"
                  sm="8"
                >
                  <v-text-field
                    v-model="verifikasiNoNIK"
                    label="Masukkan Nomor NIK"
                    color="purple"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-n5">
                <v-col
                  cols="4"
                  sm="4"
                  style="
                display: flex;
                flex-direction: column;
              "
                >
                  <span class="font-common">
                    Keterangan
                  </span>

                  <span
                    class="font-common"
                    style="
                  font-size: 12px;
                  color: #808080;
                "
                  >
                    *Wajib diisi bila menolak
                  </span>
                </v-col>

                <v-col
                  cols="8"
                  sm="8"
                >
                  <v-textarea
                    id="verifikasi-keterangan"
                    v-model="verifikasiKeterangan"
                    label="Masukkan Keterangan"
                    color="purple"
                    no-resize
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row class="mb-5">
                <v-col class="text-right">
                  <v-btn
                    class="text-capitalize mx-2 font-common-white-bold"
                    style="
                  width: 100px;
                  height: 46px;
                  background: #7A1878;
                "
                    @click="verifikasiPersonal(1)"
                  >
                    Verifikasi
                  </v-btn>

                  <v-btn
                    class="text-capitalize font-common-white-bold"
                    style="
                  width: 71px;
                  height: 46px;
                  background: #EA3E3A;
                "
                    @click="verifikasiPersonal(0)"
                  >
                    Tolak
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item key="infoPerusahaan">
              <template v-if="selectedMitra !== null">
                <v-row class="mt-5">
                  <v-col
                    class="font-common"
                    cols="4"
                    sm="4"
                  >
                    Nama Merek
                  </v-col>

                  <v-col
                    class="font-common-bold"
                    cols="8"
                    sm="8"
                  >
                    <span v-if="selectedMitra !== null">
                      {{ selectedMitra.verification_dat.company_dat.name || '-' }}
                    </span>
                  </v-col>
                </v-row>

                <v-row class="mt-5">
                  <v-col
                    class="font-common"
                    cols="4"
                    sm="4"
                  >
                    Nama Legal
                  </v-col>

                  <v-col
                    class="font-common-bold"
                    cols="8"
                    sm="8"
                  >
                    <span v-if="selectedMitra !== null">
                      {{ selectedMitra.verification_dat.company_dat.name_legal || '-' }}
                    </span>
                  </v-col>
                </v-row>

                <v-row class="mt-5">
                  <v-col
                    class="font-common"
                    cols="4"
                    sm="4"
                  >
                    Nama Direktur
                  </v-col>

                  <v-col
                    class="font-common-bold"
                    cols="8"
                    sm="8"
                  >
                    <span v-if="selectedMitra !== null">
                      {{ selectedMitra.verification_dat.company_dat.director_name || '-' }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    class="font-common"
                    cols="4"
                    sm="4"
                  >
                    Nomor Telepon
                  </v-col>

                  <v-col
                    class="font-common-bold"
                    cols="8"
                    sm="8"
                  >
                    <span v-if="selectedMitra !== null">
                      {{ selectedMitra.verification_dat.company_dat.phone || '-' }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    class="font-common"
                    cols="4"
                    sm="4"
                  >
                    Alamat
                  </v-col>

                  <v-col
                    class="font-common-bold"
                    cols="8"
                    sm="8"
                  >
                    <span v-if="selectedMitra !== null">
                      {{ selectedMitra.verification_dat.company_dat.address || '-' }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-divider />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="font-common-black-bold">
                    Dokumen Perusahaan
                  </v-col>
                </v-row>

                <template v-if="selectedMitra.verification_dat.company_dat.documents !== null">
                  <v-row
                    v-for="(v, i) in selectedMitra.verification_dat.company_dat.documents"
                    :key="'company-document-' + i"
                  >
                    <v-col
                      class="pt-5 font-common"
                      cols="5"
                      sm="5"
                    >
                      {{ v.name }}
                    </v-col>

                    <v-col
                      class="font-common-bold"
                      cols="7"
                      sm="7"
                    >
                      <span v-if="selectedMitra !== null">
                        {{ v.file_name + ' (' + v.size || 0 + ')' }}
                      </span>

                      <v-icon
                        class="ml-1"
                        style="
                        font-size: 12px;
                        cursor: pointer;
                      "
                        @click="downloadAttachment(v.file_path_view_only)"
                      >
                        fas fa-eye
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <v-col>
                    <v-divider />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="4"
                    sm="4"
                    style="
                display: flex;
                flex-direction: column;
              "
                  >
                    <span class="font-common">
                      Keterangan
                    </span>

                    <span
                      class="font-common"
                      style="
                  font-size: 12px;
                  color: #808080;
                "
                    >
                      *Wajib diisi bila menolak
                    </span>
                  </v-col>

                  <v-col
                    cols="8"
                    sm="8"
                  >
                    <v-textarea
                      id="verifikasi-keterangan-perusahaan"
                      v-model="verifikasiKeterangan"
                      label="Masukkan Keterangan"
                      color="purple"
                      no-resize
                      outlined
                    />
                  </v-col>
                </v-row>

                <v-row class="mb-5">
                  <v-col class="text-right">
                    <v-btn
                      class="font-common-bold text-capitalize mx-2"
                      style="
                  width: 100px;
                  height: 46px;
                  background: #7A1878;
                "
                      @click="verifikasiAkunPerusahaan(1)"
                    >
                      Verifikasi
                    </v-btn>

                    <v-btn
                      class="font-common-bold text-capitalize"
                      style="
                  width: 71px;
                  height: 46px;
                  background: #EA3E3A;
                "
                      @click="verifikasiAkunPerusahaan(0)"
                    >
                      Tolak
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>

      <template v-else>
        <v-card class="px-4 py-4">
          <v-row>
            <v-col>
              <span style="font-weight: bold">
                Verifikasi Mitra
              </span>
            </v-col>

            <v-col class="text-right">
              <span
                style="cursor: pointer"
                @click="dialogVerifikasiPersonal = false"
              >
                X
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="text-center py-16 font-common-bold"
              style="font-size: 24px"
            >
              <!-- Tidak ada akses -->
              <v-img
                contain
                src="@/assets/no-akses.svg"
              />
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>

    <!-- DIALOG VERIFIKASI BANK -->
    <v-dialog
      v-model="dialogVerifikasiBank"
      max-width="600"
    >
      <v-card class="px-4 py-4">
        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Verifikasi Bank Mitra
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialogVerifikasiBank = false"
            >
              X
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama Merek
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ verifikasiPerusahaan.bank_owner || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nama Bank
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ verifikasiPerusahaan.bank_name || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Nomor Rekening
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ verifikasiPerusahaan.bank_number || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="4"
            sm="4"
          >
            Atas Nama
          </v-col>

          <v-col
            class="font-common-bold"
            cols="8"
            sm="8"
          >
            {{ verifikasiPerusahaan.bank_owner || '-' }}
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span style="font-weight: bold">
              Riwayat Bank
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Nama Bank
                    </th>
                    <th class="text-left">
                      Nomor Rekening
                    </th>
                    <th class="text-left">
                      Atas Nama
                    </th>
                    <th class="text-left">
                      Tanggal Perubahan
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(v, i) in verifikasiPerusahaan.bank_historical"
                    :key="'bank-history-' + i"
                  >
                    <td>{{ v.bank_name }}</td>
                    <td>{{ v.bank_number }}</td>
                    <td>{{ v.bank_owner }}</td>
                    <td>{{ v.date }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col
            cols="4"
            sm="4"
            style="
              display: flex;
              flex-direction: column;
            "
          >
            <span>
              Keterangan
            </span>

            <span
              style="
                font-size: 12px;
                color: #808080;
              "
            >
              *Wajib diisi bila menolak
            </span>
          </v-col>

          <v-col
            cols="8"
            sm="8"
          >
            <v-textarea
              id="verifikasi-keterangan-perusahaan"
              v-model="verifikasiKeteranganPerusahaan"
              label="Masukkan Keterangan"
              color="purple"
              no-resize
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            <v-btn
              class="text-capitalize"
              style="
                width: 100px;
                height: 46px;
                background: #7A1878;
              "
              @click="verifikasiBank(1)"
            >
              Verifikasi
            </v-btn>

            <v-btn
              class="text-capitalize"
              style="
                width: 71px;
                height: 46px;
                background: #EA3E3A;
              "
              @click="verifikasiBank(2)"
            >
              Tolak
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      tabDialog: null,
      dialogVerifikasiPersonal: false,
      dialogVerifikasiBank: false,

      // Mitra:
      search: '',
      headers: [
        { text: 'Nama Merek', align: 'start', value: 'name', sortable: false },
        { text: 'Nama Pimpinan', value: 'owner_name' },
        { text: 'Kota/Kab', value: 'akot_string', sortable: false },
        { text: 'Durasi Kontrak', value: 'contract_validity_date' },
        { text: 'PBL', value: 'share_percent' },
        { text: 'Saldo Dompet', value: 'wallet_saldo' },
        { text: 'Total Aset', value: 'asset_recap.total' },
        { text: 'Status', value: 'status_string' },
        { text: 'Verifikasi', value: 'verification_dat', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      mitra: [],

      isAllowDetail: false,
      isAllowVerification: false,

      verifikasiNama: '',
      verifikasiEmail: '',
      verifikasiNoNIK: '',
      verifikasiKeterangan: '',

      selectedMitra: null,

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      // Perusahaan / Bank:
      searchPerusahaan: '',
      headerPerusahaan: [
        { text: 'Nama Mitra', align: 'start', value: 'name', sortable: false },
        { text: 'Nama Bank', value: 'bank_name', sortable: false },
        { text: 'Nomor Rekening', value: 'bank_number', sortable: false },
        { text: 'Atas Nama', value: 'bank_owner', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      perusahaan: [
        {
          hash: 'pcrWvN4SCO5Nl7hftSMy1iHShET8lao6SD94JgrhivRnRTukJtgA6WPIDbmadzPGRo8ZJruJ49TcLOxmUXqADOHzT5eXxEtlNDgh',
          phone: '081213',
          bank_name: 'BANK MAYBANK SYARIAH INDONESIA',
          bank_logo: null,
          bank_owner: 'Agensi Iklan Indonesia, PT',
          bank_number: '64564567',
          bank_verified: false,
          bank_historical: [
            {
              bank_owner: 'Jeff',
              bank_number: '232322323',
              bank_name: 'BANK MAYBANK SYARIAH INDONESIA',
              bank_logo: null,
              date: '11 Nov 2020',
            },
            {
              bank_owner: 'Jeff',
              bank_number: '232322323',
              bank_name: 'BANK MAYBANK SYARIAH INDONESIA',
              bank_logo: null,
              date: '11 Nov 2020',
            },
            {
              bank_owner: 'Jeff',
              bank_number: '232322323',
              bank_name: 'BANK MAYBANK SYARIAH INDONESIA',
              bank_logo: null,
              date: '11 Nov 2020',
            },
          ],
        },
      ],

      pagePerusahaan: 1,
      pageCountPerusahaan: 0,
      itemCountPerusahaan: 0,
      itemTotalPerusahaan: 0,

      verifikasiPerusahaan: {
        bank_historical: [],
        bank_logo: 'null',
        bank_name: '',
        bank_number: '',
        bank_owner: '',
        bank_verified: false,
        hash: '',
        phone: '',
      },

      verifikasiNPWP: '',
      verifikasiKeteranganPerusahaan: '',

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      partnerCompanyView () {
        let v = false
        if (this.userMenus.includes('PARTNER_COMPANY_VIEW')) v = true

        return v
      },

      partnerVerification () {
        let v = false
        if (this.userMenus.includes('PARTNER_COMPANY_VERIFICATION')) v = true

        return v
      },

      partnerBankVerificationView () {
        let v = false
        if (this.userMenus.includes('PARTNER_BANK_VERIFICATION_VIEW')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      this.getPerusahaan(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/partner/company', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.mitra = res.data.data.list

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getPerusahaan (page, limit, filter) {
        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/partner/bank/verification', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.perusahaan = res.data.data.list
            // Pagination:
            vm.pageCountPerusahaan = res.data.data.pagination.page_total
            vm.itemCountPerusahaan = res.data.data.pagination.item_count
            vm.itemTotalPerusahaan = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiPersonal (status) {
        if (!this.verifikasiKeterangan.trim().length > 0 && status === 0) {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan').focus()
          }, 100)

          return
        }

        const requestBody = {
          prt_hash: this.selectedMitra.hash,
          submit_status: status,
          no_ktp: this.verifikasiNoNIK,
          reason: this.verifikasiKeterangan,
        }

        axios.post('/v1/t/partner/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.verifikasiKeterangan = ''
            this.dialogVerifikasiBank = false
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiAkunPerusahaan (status) {
        console.log(this.selectedMitra)
        if (!this.verifikasiKeterangan.trim().length > 0 && status === 0) {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan-perusahaan').focus()
          }, 100)
          return
        }

        const docs = {}
        if (this.selectedMitra !== null) {
          if (this.selectedMitra.verification_dat.company_dat.documents !== null) {
            this.selectedMitra.verification_dat.company_dat.documents.map(v => {
              docs[v.type] = status
            })
          }
        }

        const requestBody = {
          prt_comp_hash: this.selectedMitra.verification_dat.company_dat.hash,
          submit_status: status,
          reason: this.verifikasiKeterangan,
          doc_arr: JSON.stringify(docs),
          // keterangan: verifikasiKeteranganPerusahaan
        }

        axios.post('/v1/t/partner/company/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.verifikasiKeterangan = ''
            this.dialogVerifikasiBank = false
            this.dialogVerifikasiPersonal = false
            this.initialize(1, this.itemsPerPage)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiBank (status) {
        if (!this.verifikasiKeteranganPerusahaan.trim().length > 0 && status === 0) {
          setTimeout(() => {
            document.getElementById('verifikasi-keterangan-perusahaan').focus()
          }, 100)
          return
        }

        const requestBody = {
          prt_comp_hash: this.verifikasiPerusahaan.hash,
          submit_status: status,
        }

        axios.post('/v2/t/partner/bank/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.verifikasiKeteranganPerusahaan = ''
            this.dialogVerifikasiBank = false
            this.getPerusahaan(1, this.itemsPerPage, '')
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      downloadAttachment (data) {
        // const type = data.file_type.toUpperCase()
        // if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
        //   const modal = document.getElementById('myModal')
        //   const modalImg = document.getElementById('img01')
        //   modal.style.display = 'block'
        //   if (data.url) modalImg.src = data.url
        //   if (data.path) modalImg.src = data.path
        //   if (data.file_path_view_only) modalImg.src = data.file_path_view_only
        //   const span = document.getElementsByClassName('close')[0]
        //   span.onclick = function () {
        //     modal.style.display = 'none'
        //   }
        // } else if (type === 'PDF') {
        //   window.open(data.url, '_blank')
        // } else {
        //   window.open(data.url_download, '_blank')
        // }
        const modal = document.getElementById('myModal')
        const modalImg = document.getElementById('img01')
        modal.style.display = 'block'
        modalImg.src = data
        const span = document.getElementsByClassName('close')[0]
        span.onclick = function () {
          modal.style.display = 'none'
        }
      },

      showDialogVerifikasiPersonal (item) {
        // console.log(item)
        this.verifikasiKeterangan = ''
        this.verifikasiNoNIK = ''
        this.verifikasiNPWP = ''
        this.verifikasiKeteranganPerusahaan = ''

        this.selectedMitra = item
        this.dialogVerifikasiPersonal = true
      },

      showDialogVerifikasiPerusahaan (item) {
        // console.log(item)
        this.verifikasiKeteranganPerusahaan = ''
        this.verifikasiPerusahaan = item
        this.dialogVerifikasiBank = true
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      onChangePagePerusahaan (e) {
        this.initialize(e, this.itemsPerPage)
      },

      toPersonalDetail (item) {
        this.$router.push({ name: 'MitraDetail', params: { id: item.hash } })
      },

      toPersonalPerusahaanDetail (item) {
        this.$router.push({ name: 'PenyewaPerusahaanDetail', params: { id: item.hash } })
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },

      filterDataPerusahaan () {
        this.initialize(1, this.itemsPerPage, this.searchPerusahaan)
      },

      filterClearPerusahaan () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 1000;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
